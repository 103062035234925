export default [
    {
        header: 'Tenders',
    },
    {
        title: 'Tender Page',
        icon: 'FileIcon',
        route: { name: 'tender-page' }
    },
    {
        title: 'Tender Items',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'List',
                route: { name: 'tender-list' },
            },
            {
                title: 'Add',
                route: { name: 'tender-item' },
            }
        ],
    }
]