export default [
  {
    header: "Hotels & Discounts",
  },
  {
    title: "Hotel",
    icon: "BoxIcon",
    children: [
      {
        title: "List",
        route: { name: "hotel-list" },
      },
      {
        title: "Add",
        route: { name: "hotel-add" },
      },
    ],
  },
  {
    title: "Discounts",
    icon: "BoxIcon",
    children: [
      {
        title: "List",
        route: { name: "discount-list" },
      },
      {
        title: "Add",
        route: { name: "discount-add" },
      },
    ],
  },
];
