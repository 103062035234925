export default [{
    header: 'Others',
},
{
    title: 'Amenities',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'amenity-list' },
        },
        {
            title: 'Add',
            route: { name: 'amenity-add' },
        }
    ],
},
{
    title: 'Themes',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'theme-list' },
        },
        {
            title: 'Add',
            route: { name: 'theme-add' },
        }
    ],
},
{
    title: 'Property Types',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'property-type-list' },
        },
        {
            title: 'Add',
            route: { name: 'property-type-add' },
        }
    ],
},
{
    title: 'Attractions',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'attraction-list' },
        },
        {
            title: 'Add',
            route: { name: 'attraction-add' },
        }
    ],
},
{
    title: 'Explore MH',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'explore-maharashtra-list' },
        },
        {
            title: 'Add',
            route: { name: 'explore-maharashtra-add' },
        },
        {
            title: 'Explore Items',
            children: [
                {
                    title: 'List',
                    route: { name: 'explore-item-list' },
                },
                {
                    title: 'Add',
                    route: { name: 'explore-item-add' },
                }
            ]
        }
    ],
},
{
    title: 'Reviews',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'review-list' },
        },
        {
            title: 'Add',
            route: { name: 'review-add' },
        },
    ],
},
{
    title: 'Things To Do',
    icon: 'BoxIcon',
    children: [
        {
            title: 'List',
            route: { name: 'things-to-do-list' },
        },
        {
            title: 'Add',
            route: { name: 'things-to-do-add' },
        }
    ],
},]