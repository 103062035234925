import eventsNews from "./events-news";
import gallery from "./gallery";
import tenders from "./tenders";
import hotels from "./hotels";
import refund from "./refund";
import reports from "./reports";
import others from "./others";
import pages from "./pages";
import team from "./team";

export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  ...tenders,
  ...pages,
  ...hotels,
  ...gallery,
  ...eventsNews,
  ...team,
  ...others,
  ...refund,
  ...reports,
];
