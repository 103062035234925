export default [
    {
        header: 'Pages',
    },
    {
        title: 'Home Page',
        icon: 'HomeIcon',
        route: { name: 'home-page' }
    },
    {
        title: 'About Us',
        icon: 'FileIcon',
        children: [
            {
                title: 'Index Page',
                route: { name: 'about-us-page' },
            },
            {
                title: 'RTI',
                route: { name: 'rti-page' },
            },
            {
                title: 'Organization Structure',
                route: { name: 'about-us-org-structure' },
            },
            {
                title: 'Property Details',
                route: { name: 'property-details-page' }
            }
        ],
    },
    {
        title: 'Covid 19',
        icon: 'FileIcon',
        route: { name: 'covid-page' }
    },
    {
        title: 'Tourism',
        icon: 'FileIcon',
        route: { name: 'tourism-page' }
    },
    {
        title: 'Government Resolutions',
        icon: 'FileIcon',
        route: { name: 'GR-page' }
    },
    {
        title: 'Media',
        icon: 'ImageIcon',
        children: [
            {
                title: 'Add',
                route: { name: 'media-gallery-page'}
            },
            {
                title: 'Gallery Items',
                icon: 'ImageIcon',
                children: [
                    {
                        title: 'List',
                        route: { name: 'gallery-list' },
                    },
                    {
                        title: 'Add',
                        route: { name: 'gallery-item-add' },
                    }
                ],
            }

        ],
      
    },
    {
        title: 'Concession Document',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'List',
                route: { name: 'concession-list' },
            },
            {
                title: 'Add',
                route: { name: 'concession-add' },
            }
        ],
    },
    {
        title: 'Right To Public',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'List',
                route: { name: 'rtp-list' },
            },
            {
                title: 'Add',
                route: { name: 'rtp-add' },
            }
        ],
    },
    {
        title: 'Empanelled Agencies',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'List',
                route: { name: 'empanelled-list' },
            },
            {
                title: 'Add',
                route: { name: 'empanelled-add' },
            }
        ],
    },
    {
        title: 'Feedback',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'Add',
                route: { name: 'feedback-page' },
            },
            {
                title: 'Feedback List',
                route: { name: 'feedback-list' },
            }
        ],
    },
    {
        title: 'Privacy Policy',
        icon: 'FileTextIcon',
        route: { name: 'privacy-page' }
    },
    {
        title: 'Cancellation Policy',
        icon: 'FileTextIcon',
        route: { name: 'cancellation-page' }
    },
    {
        title: 'Terms & Conditions',
        icon: 'FileTextIcon',
        route: { name: 'terms-page' }
    },
    {
        title: 'FAQs',
        icon: 'FileIcon',
        route: { name: 'faq-page' }
    },
    {
        title: 'Contact Us',
        icon: 'MessageSquareIcon',
        route: { name: 'contact-page' }
    }
]