export default [
  {
    header: "Refund & Cancellation",
  },
  {
    title: "Refund",
    icon: "DollarSignIcon",
    children: [
      {
        title: "Transactions",
        route: { name: "refund-list" },
      },
      {
        title: "Initiate Refund",
        route: { name: "refund-transaction" },
      },
    ],
  },
];
