export default [
    {
        header: 'Organization',
    },
    {
        title: 'Team',
        icon: 'UsersIcon',
        children: [
            {
                title: 'List',
                route: { name: 'team-list' },
            },
            {
                title: 'Add',
                route: { name: 'team-add' },
            }
        ],
    },
    {
        title: 'Resort Manager',
        icon: 'UserIcon',
        children: [
            {
                title: 'List',
                route: { name: 'resort-manager-list' },
            },
            {
                title: 'Add',
                route: { name: 'resort-manager-add' },
            }
        ],
    },
    {
        title: 'Users',
        icon: 'UserIcon',
        children: [
            {
                title: 'List',
                route: { name: 'user-list' },
            },
            {
                title: 'Add',
                route: { name: 'user-add' },
            }
        ],
    },
    {
        title: 'Regional Office',
        icon: 'BriefcaseIcon',
        children: [
            {
                title: 'List',
                route: { name: 'regional-office-list' },
            },
            {
                title: 'Add',
                route: { name: 'regional-office-add' },
            }
        ],
    },
    {
        title: 'Booking Counters',
        icon: 'BriefcaseIcon',
        children: [
            {
                title: 'List',
                route: { name: 'booking-counter-list' },
            },
            {
                title: 'Add',
                route: { name: 'booking-counter-add' },
            }
        ],
    }
]