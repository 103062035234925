export default [
    {
        header: 'Events & News',
    },
    {
        title: 'Events',
        icon: 'ClockIcon',
        children: [
            {
                title: 'List',
                route: { name: 'events-list' },
            },
            {
                title: 'Add',
                route: { name: 'events-add' },
            }
        ],
    },
    {
        title: 'News',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'List',
                route: { name: 'news-list' },
            },
            {
                title: 'Add',
                route: { name: 'news-add' },
            }
        ],
    }
]