export default [
  {
    header: "Reports",
  },
  {
    title: "Report",
    icon: "FileTextIcon",
    children: [
      {
        title: "Booking Reports",
        route: { name: "booking-reports" },
      },
      {
        title: "Cancel Booking Reports",
        route: { name: "cancel-booking-reports" },
      },
      {
        title: "E-Invoices Reports",
        route: { name: "e-invoices-reports" },
      },
    ],
  },
];
