export default [
    {
        header: 'Media Gallery',
    },
    {
        title: 'Gallery',
        icon: 'ImageIcon',
        children: [
            {
                title: 'View All',
                route: { name: 'gallery-view' },
            },
            {
                title: 'Add New',
                route: { name: 'gallery-add' },
            }
        ],
    },
]